<template>
  <div class="fixed-bottom">
    <div class="rodape bg-white pb-1">
      <b-row class="">
        <b-col class="text-center">
          <div :class="`rounded-circle icone ml-3  `">
            <router-link :to="'/dashboard'">
              <i
                class="fa fa-fw fa-home "
                :style="
                  `color:${
                    rotaAtual == 'dashboard' ? temaCor.topoApp : '#6c757d'
                  }`
                "
              ></i
            ></router-link>
          </div>
        </b-col>
        <b-col class="text-center">
          <div :class="`rounded-circle icone ml-3  `">
            <router-link :to="'/cartaoVirtual'">
              <i
                class="fa fa-fw fa-id-card-o "
                :style="
                  `color:${
                    rotaAtual == 'cartaoVirtual' ? temaCor.topoApp : '#6c757d'
                  }`
                "
              ></i
            ></router-link>
          </div>
        </b-col>

        <b-col class="text-center">
          <div :class="`rounded-circle icone ml-3  `">
            <router-link :to="'/favoritos'">
              <i
                class="fa fa-fw fa-star-o "
                :style="
                  `color:${
                    rotaAtual == 'favoritos' ? temaCor.topoApp : '#6c757d'
                  }`
                "
              ></i
            ></router-link>
          </div>
        </b-col>

        <b-col class="text-center">
          <div
            @click="openMenuLateral()"
            :class="`rounded-circle icone ml-3  `"
          >
            <font-awesome-icon :icon="['fa', 'bars']" class=" text-secondary" />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import { RadialMenu, RadialMenuItem } from "vue-radial-menu";
export default {
  name: "DashBoard",
  props: {
    decoded: Object,
  },
  data() {
    return {
      isOpen: false,
      invisibleClass: "",
      color: "#348aa7",
      //items: ["Cotação", "PayPag", "Vistoria"],
      items: ["Cotação"],
      lastClicked: "click on something!",
      rotaAtual: null,
      temaCor: {},
    };
  },
  methods: {
    openMenuLateral() {
      this.$emit("OpenMenuLateral", true);
    },
    handleClick(item) {
      this.lastClicked = item;
      if (item == "Cotação") {
        this.$router.push("NovaCotacao");
      }
      console.log(item);
    },
    verificaToken() {
      var dadosEmpresa = JSON.parse(localStorage.getItem("dadosEmpresa"));
      this.temaCor.topoApp = dadosEmpresa.CorBackgroundCabecalho;
    },
    open() {
      this.isOpen = true;
      this.color = "red";
      this.invisibleClass = "invisible";
    },
    close() {
      this.isOpen = false;
      this.color = "#348aa7";
      this.invisibleClass = "";
    },
    currentRouteName() {
      this.rotaAtual = this.$route.name;
    },
  },
  mounted() {
    this.currentRouteName();
    this.verificaToken();
  },
  components: { RadialMenu, RadialMenuItem },
};
</script>
<style>
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.rodape {
  -webkit-box-shadow: 0px 0px 19px 2px rgba(0, 0, 0, 0.59);
  box-shadow: 0px 0px 19px 2px rgba(0, 0, 0, 0.59);
}
.fixed-bottom {
  z-index: 1039;
}
.icone {
  text-align: center;
  vertical-align: middle;
  font-size: 22px;
  margin: 10px 0;
}
.btnplus {
  margin-left: 27%;
  width: 60px;
  height: 60px;
  font-size: 15px;
  text-align: center;
  vertical-align: middle;
  /*position: relative;*/
}
.backdiv {
  background-color: #343a40;
  height: 170px;
  position: absolute;
  bottom: 0px;
  width: 100%;
}
.invisible {
  visibility: hidden;
}
.vue-radial-menu-item {
  margin-left: -10px;
}
.vue-radial-menu-item span.texto {
  font-size: 13px;
  font-weight: normal;
}
.vue-radial-menu-item span.icone {
  font-size: 15px;
  font-weight: normal;
  display: block;
}
</style>
